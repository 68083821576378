import { render, staticRenderFns } from "./NFT.vue?vue&type=template&id=79a62c18&scoped=true"
import script from "./NFT.vue?vue&type=script&lang=js"
export * from "./NFT.vue?vue&type=script&lang=js"
import style0 from "./NFT.vue?vue&type=style&index=0&id=79a62c18&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79a62c18",
  null
  
)

export default component.exports